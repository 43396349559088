import { useCMS, UseCMSOptions } from "./useCMS";

export type UseCMSGatedContentPromotion = {
  _id: string;
  title?: string;
  active?: boolean;
  description?: string;
  button?: string;
  featureImage?: string;
  customURL?: string;
  gatedContentPage?: string;
  regionTags?: string[];
  pageTags?: string[];
  backgroundColor?: string;
  textColor?: string;
  buttonBackgroundColor?: string;
  buttonTextColor?: string;
};

interface UseCMSGatedContentPromotionFilter {
  regionTags?: string;
  pageTags?: string;
}

export interface UseCMSGatedContentPromotionOptions extends UseCMSOptions {
  filter?: UseCMSGatedContentPromotionFilter;
}

export const useCMSGatedContentPromotion = (
  options: UseCMSGatedContentPromotionOptions,
  ready?: boolean
) => {
  const { data, loading } = useCMS<UseCMSGatedContentPromotion>({
    ...options,
    throttler: {
      group: "gated-content-promotion",
      action: "reload",
      delay: 1000,
    },
    endpoint: "gated-content-promotion/filter",
    ready,
  });

  return {
    promotions: data,
    loading,
  };
};
