import { fetchCMSForSSR, useCMS, UseCMSOptions } from "./useCMS";

export type UseCMSTestimonial = {
  _id: string;
  title?: string;
  subtitle?: string;
  coverImage?: string;
  profilePhoto?: string;
  description?: string;
  updatedAt?: string;
  createdAt?: string;
  plainTags?: string[];
  digitalDropTags?: string[];
  licensorTags?: string[];
  scenicProjectionTags?: string[];
  showVariantTags?: string[];
  showBrandTags?: string[];
  choreoGuideTags?: string[];
  solutionTags?: string[];
  projectorTags?: string[];
};

interface UseCMSTestimonialFilter {
  plainTags?: string[];
  digitalDropTags?: string[];
  licensorTags?: string[];
  scenicProjectionTags?: string[];
  showVariantTags?: string[];
  showBrandTags?: string[];
  choreoGuideTags?: string[];
  solutionTags?: string[];
  projectorTags?: string[];
}

interface UseCMSTestimonialExists {
  plainTags?: true;
  digitalDropTags?: true;
  licensorTags?: true;
  scenicProjectionTags?: true;
  showVariantTags?: true;
  showBrandTags?: true;
  choreoGuideTags?: true;
  solutionTags?: true;
  projectorTags?: true;
}

export interface UseCMSTestimonialOptions extends UseCMSOptions {
  filter?: UseCMSTestimonialFilter;
  exists?: UseCMSTestimonialExists;
}

export const useCMSTestimonials = (
  options?: UseCMSTestimonialOptions,
  ready?: boolean
) => {
  const { data, loading, page, pages } = useCMS<UseCMSTestimonial>({
    ...options,
    throttler: {
      group: "testimonials",
      action: "reload",
      delay: 1000,
    },
    endpoint: "testimonial/filter",
    ready,
  });

  return {
    testimonials: data,
    loading,
    page,
    pages,
  };
};

export const requestTestimonialsSSR = async (
  options?: UseCMSTestimonialOptions
) => {
  return await fetchCMSForSSR({
    ...options,
    endpoint: "testimonial/filter",
  });
};
