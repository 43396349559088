import { UseCMSHomepage } from "@with-nx/hooks-n-helpers";
import { VisibleContainer } from "@with-nx/simple-ui/molecules";
import {
  CatalogLayout,
  PromotionSection,
  TestimonialQuotes,
} from "@with-nx/simple-ui/organisms";
import { Products } from "libs/hooks-n-helpers/src/types";
import { Box } from "simple-effing-primitive-layout";

import { FormsCTA, LatestDigitalDrops } from "../home";
import {
  MobileCommercialTemplate,
  MobileCommunityTemplate,
  MobileFeaturedShowsTemplate,
  MobileInstagramTemplate,
  MobilePartnersTemplate,
  MobileSlidersTemplate,
  MobileStoriesTemplate,
  MobileTestimonialsTemplate,
} from "../mobile";

interface MobileHomePageProps {
  homepage?: UseCMSHomepage;
  latestDigitalDrops?: Products;
}

export const MobileHomePage = ({
  homepage,
  latestDigitalDrops,
}: MobileHomePageProps) => {
  return (
    <CatalogLayout transparentFixedNavBar>
      <section className="mobile">
        <MobileSlidersTemplate homepage={homepage} />
        <MobileStoriesTemplate homepage={homepage} />

        <VisibleContainer>
          <>
            <LatestDigitalDrops drops={latestDigitalDrops} />
            {homepage?.testimonials?.length ? (
              <Box>
                <TestimonialQuotes
                  testimonials={homepage?.testimonials?.slice(0, 5) || []}
                />
              </Box>
            ) : undefined}
            <MobileCommunityTemplate />
            <MobileFeaturedShowsTemplate homepage={homepage} />
            <PromotionSection page="Homepage" />
            <FormsCTA />
            <MobileTestimonialsTemplate homepage={homepage} />
            <MobileCommercialTemplate />
            <MobileInstagramTemplate />
            <MobilePartnersTemplate homepage={homepage} />
          </>
        </VisibleContainer>
      </section>
    </CatalogLayout>
  );
};

export default MobileHomePage;
