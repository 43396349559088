import { Formatter, useMobile, useWindowSize } from "@with-nx/hooks-n-helpers";
import { DesignedCard } from "@with-nx/simple-ui/atoms";
import { domAnimation, LazyMotion, m } from "framer-motion";
import shuffle from "lodash/shuffle";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { LARGE_DESKTOP_BREAKPOINT } from "../mobile-view-switcher/mobile-view-switcher";

const Card = ({ image }: { image?: string }) => {
  const window = useWindowSize();
  const isLargeScreen =
    window?.width && window?.width > LARGE_DESKTOP_BREAKPOINT;
  const width = isLargeScreen ? 350 : 252;

  return (
    <Box parse={`w:${width} br:5 oa:hidden`}>
      <DesignedCard size="16x9" pop={false} image={image} />
    </Box>
  );
};

const PlainImage = ({ image }: { image?: string }) => {
  return (
    <div
      style={{
        minWidth: 252,
        width: 252,
        height: 141.75,
        borderRadius: 5,
        overflow: "hidden",
        objectFit: "cover",
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
};

const Row = ({
  images,
  size = 24,
  amount = 24,
  plain,
}: {
  images?: string[];
  size?: number;
  amount?: number;
  plain?: boolean;
}) => {
  return (
    <Box parse="d:flex">
      {[...Array(amount)].map((item, i) => (
        <Box key={i} parse="ma:9 ">
          {plain ? (
            <PlainImage image={images?.[i % size]} />
          ) : (
            <Card image={images?.[i % size]} />
          )}
        </Box>
      ))}
    </Box>
  );
};

const AnimatedRow = ({
  x = 0,
  direction = "right",
  duration = 10,
  images = [],
  size = 24,
  amount = 24,
  delay,
  plain,
}: {
  x?: number;
  direction?: string;
  duration?: number;
  images?: string[];
  size?: number;
  amount?: number;
  delay?: number;
  plain?: boolean;
}) => {
  const animate = direction === "right" ? 500 : -500;

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        animate={{ x: animate }}
        initial={{ x }}
        transition={{
          delay: delay || 0,
          duration: duration,
          repeat: Infinity,
          repeatType: "mirror",
          repeatDelay: 0,
          type: "tween",
        }}
      >
        <Row plain={plain} size={size} amount={amount} images={images} />
      </m.div>
    </LazyMotion>
  );
};

export const AnimatedInfiniteSlantedCards = ({
  images,
  size = 24,
  rows = 3,
  duration,
}: {
  images?: string[];
  size?: number;
  rows?: number;
  duration?: number;
}) => {
  const chunks = Formatter.chunks(images || [], size) as string[][];

  return (
    <Box>
      {[...Array(rows)].map((row, r) => (
        <AnimatedRow
          size={size}
          images={chunks?.[r] || shuffle(images || [])}
          direction={["right", "left"][r % 2]}
          x={[-83, 79, 0][r % 3]}
          duration={duration || [10, 20, 15][r % 3]}
          key={r}
        />
      ))}
    </Box>
  );
};

export const AnimatedInfiniteLoadingElements = () => {
  const mobile = useMobile();

  const images = [...Array(72)].map((_, i) => `/assets/drops/${i + 1}.jpg`);
  const size = mobile ? 6 : 10;
  const rows = mobile ? 6 : 10;
  const chunks = Formatter.chunks(images || [], size) as string[][];

  const width = mobile ? 2000 : 3000;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 99999,
        backgroundColor: "var(--foreground)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{ transform: "rotate(5deg)", position: "relative", zIndex: 2 }}
      >
        {[...Array(rows)].map((row, r) => (
          <AnimatedRow
            size={size}
            delay={0}
            images={chunks?.[r] || shuffle(images || [])}
            direction={["right", "left"][r % 2]}
            x={[-width, width][r % 2]}
            duration={6}
            key={r}
            amount={size}
            plain={true}
          />
        ))}
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="/assets/logo/logo-broadway-media-primary.svg"
          alt="Broadway Media Logo"
          style={{ width: "100px", opacity: 0.5 }}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export const AnimatedInfiniteLoadingCards = () => {
  const [display, _display] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      _display(false);
    }, 2000);

    if (!sessionStorage?.getItem("loaded")) {
      sessionStorage?.setItem("loaded", "true");
      _display(true);
      clearTimeout(timeout);
      setTimeout(() => {
        _display(false);
      }, 8000);
    }
  }, []);

  if (!display) {
    return null;
  }

  return <AnimatedInfiniteLoadingElements />;
};

export default AnimatedInfiniteSlantedCards;
