/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import useService from "../useService";
import { fetchCMSForSSR } from "./useCMS";
import { UseCMSBlog } from "./useCMSBlogs";
import { UseCMSFeatured } from "./useCMSFeatureds";
import { UseCMSPartner } from "./useCMSPartners";
import { UseCMSSlide } from "./useCMSSlides";
import { UseCMSTestimonial } from "./useCMSTestimonials";

export type UseCMSHomepage = {
  partners: UseCMSPartner[];
  testimonials: UseCMSTestimonial[];
  featureds: UseCMSFeatured[];
  blogs: UseCMSBlog[];
  slides: UseCMSSlide[];
};

export const requestCMSHomepageSSR = async (
  region: string | number
): Promise<UseCMSHomepage> =>
  fetchCMSForSSR({
    endpoint: "collection/homepage",
    method: "GET",
    filter: {
      region,
    },
  });

export const useCMSHomepage = ({ region }: { region?: string | number }) => {
  const [data, _data] = useState<UseCMSHomepage | undefined>(undefined);

  useEffect(() => {
    if (!region) return;

    let current = true;

    const makeRequestToCMS = useService("cms", {
      cache: 60_000,
      timeout: 10_000,
      retry: 3,
    });

    (async () => {
      const request = await makeRequestToCMS(
        "GET",
        "/api/v1/collection/homepage",
        {
          region,
        }
      );

      if (request && current) {
        _data(request as UseCMSHomepage);
      }
    })();

    return () => {
      current = false;
    };
  }, [region]);

  return { data, loading: !data };
};
