import { Formatter, UseCMSHomepage } from "@with-nx/hooks-n-helpers";
import { DesignedButton } from "@with-nx/simple-ui/atoms";
import useMediaQuery from "libs/hooks-n-helpers/src/useMediaQuery";
import Image from "next/image";
import { useState } from "react";

export const MobileSlidersTemplate = ({
  homepage,
  showSliderControls,
}: {
  homepage?: UseCMSHomepage;
  showSliderControls?: boolean;
}) => {
  const [current, _current] = useState(0);
  const slide = homepage?.slides?.[current];

  return (
    <section className="@slide">
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          zIndex: "1",
        }}
      >
        <Image
          src="/assets/header_draft.webp"
          layout="fill"
          objectFit="cover"
          priority
          objectPosition="top center"
        />
      </div>

      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          zIndex: "1",
        }}
      >
        <Image
          src="/assets/hero/homepage-gradient-webp.webp"
          sizes="100vw"
          layout="fill"
          priority
          objectPosition="center"
          objectFit="cover"
        />
      </div>

      <div className="@slide_content">
        {slide ? (
          <div className="container">
            {slide?.logoImage ? (
              <Image
                src={Formatter.compressed(slide?.logoImage, 512, true)}
                width={256}
                height={140}
                objectFit="contain"
                objectPosition="center left"
                alt={slide?.title}
                priority
              />
            ) : undefined}
            <h2 className="!_t d:block mb:16">{slide.title}</h2>
            <p className="!_p d:block mb:32">{slide.description}</p>
            <DesignedButton
              label={slide.buttonText}
              theme="white"
              size="large"
              href={slide.buttonURL}
            />
          </div>
        ) : undefined}
      </div>
    </section>
  );
};

export default MobileSlidersTemplate;
